import PaypalElement from '@adyen/adyen-web/dist/types/components/PayPal'
import { CreateCheckoutSessionResponse } from '@adyen/api-library/lib/src/typings/checkout/createCheckoutSessionResponse'
import { yupResolver } from '@hookform/resolvers/yup'
import useUserCentrics from '@hooks/useUserCentrics'
import { Maybe } from 'graphql/jsutils/Maybe'
import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Resolver, useForm } from 'react-hook-form'
import { useTheme } from 'styled-components'
import * as yup from 'yup'

import { BoxBackgrounds } from '@components/atoms/Box/Box'
import {
  ButtonModifier,
  ButtonTypes,
  IconAligns,
} from '@components/atoms/Button/Button'
import ErrorText from '@components/atoms/ErrorText'
import InputCheckbox from '@components/atoms/Form/InputCheckbox'
import InputRadioGroup from '@components/atoms/Form/InputRadioGroup'
import InputText from '@components/atoms/Form/InputText'
import FullPopup from '@components/atoms/FullPopup'
import Col from '@components/atoms/Grid/Col'
import Row from '@components/atoms/Grid/Row'
import Headline from '@components/atoms/Headline'
import { HeadlineImportances } from '@components/atoms/Headline/Headline'
import IconArrowRight from '@components/atoms/Icons/IconArrowRight'
import IconCheck from '@components/atoms/Icons/IconCheck'
import IconInfo from '@components/atoms/Icons/IconInfo'
import ListItem from '@components/atoms/ListItem'
import { Text, TextVariants } from '@components/atoms/Text/Text'

import { formatEuro } from '@helper/currency'
import { PropsWithClassName } from '@helper/PropsWithClassName'

import { Contract, Cost, CostEdge } from '@definitions/types/symfonyTypesd'
import { Studio } from '@definitions/types/symfonyTypesd'

import useHandlePayment from '../../../hooks/useHandlePayment'
import { OrderedList } from '../../atoms/OrderedList/OrderedList'
import { FormUserDataType } from '../Forms/FormUserData/FormUserData'
import {
  FinalizeCostsContainer,
  FinalizeCostsRow,
  FinalizeInfoRow,
  FinalizePaymentBox,
  Form,
  Kleingedrucktes,
  PaymentBox,
  PaypalContainer,
  PaypalInnerContainer,
  Root,
  StyledBox,
  StyledButton,
  StyledCol,
  StyledLogoPaypal,
  StyledLogoPaypalMini,
  StyledLogoSepa,
  StyledLogoSepaMini,
  StyledMetaFinalizeCostsText,
  StyledMetaText,
  StyledParagraphText,
} from './Payment.styles'

interface Props extends PropsWithClassName {
  contract: Contract
  studio: Studio
  formData: FormUserDataType
  handlePayment: (
    data: FormPaymentType,
    paymentSession: CreateCheckoutSessionResponse | null
  ) => void
  registrationError?: string | null | undefined
}

export enum PaymentMethod {
  PAYPAL = 'paypal',
  SEPA = 'sepa',
}

export enum DebitDate {
  FIRST = '1',
  FIFTHTEENTH = '15',
}

export interface FormPaymentType {
  paymentmethod: PaymentMethod
  debitdate: DebitDate
  name: string
  iban: string
  directdebitmandate: boolean
  sameperson: boolean
}

const paymentMethodConditionalValidationString = {
  is: (paymentMethod: string) => paymentMethod === PaymentMethod.SEPA,
  otherwise: () => yup.string().notRequired(),
}

const paymentMethodConditionalValidationBoolean = {
  is: (paymentMethod: string) => paymentMethod === PaymentMethod.SEPA,
  otherwise: () => yup.boolean().notRequired(),
}

const schema = yup.object().shape({
  paymentmethod: yup.string().required('Dies ist ein Pflichtfeld.'),
  debitdate: yup
    .string()
    .required('Dies ist ein Pflichtfeld.')
    .when('paymentmethod', paymentMethodConditionalValidationString),
  name: yup
    .string()
    .required('Dies ist ein Pflichtfeld.')
    .when('paymentmethod', paymentMethodConditionalValidationString),
  iban: yup
    .string()
    .matches(
      /^((DE[0-9]{2}[0-9]{8}[0-9]{10}|LU[0-9]{2}[0-9]{4}[0-9]{12}|AT[0-9]{2}[0-9]{5}[0-9]{10})|(AT\d{18}(?!\d))|(LU\d{5}[0-9A-Z]{13}))$/,
      'Ungültiges IBAN-Format. Nur deutsche, luxemburgische oder österreichische IBANs sind zugelassen.'
    )
    .required('Dies ist ein Pflichtfeld.')
    .when('paymentmethod', paymentMethodConditionalValidationString),
  directdebitmandate: yup
    .boolean()
    .required()
    .when('paymentmethod', paymentMethodConditionalValidationBoolean)
    .oneOf([true], 'Sie müssen zustimmen, um fortzufahren.'),
  sameperson: yup
    .boolean()
    .required()
    .oneOf([true], 'Sie müssen zustimmen, um fortzufahren.')
    .when('paymentmethod', paymentMethodConditionalValidationBoolean)
    .oneOf([true], 'Sie müssen zustimmen, um fortzufahren.'),
})

const popupContent = (
  contract: Contract,
  studio: Studio,
  formData: FormUserDataType
) => {
  return (
    <div style={{ maxHeight: '75vh', overflowY: 'auto' }}>
      <Row>
        <StyledCol xs={12} sm={6} md={4} lg={4}>
          <StyledBox
            icon={<IconCheck />}
            background={BoxBackgrounds.TRANSPARENT}
          >
            <Headline importance={HeadlineImportances.h5}>
              Kostenübersicht
            </Headline>
            {contract.costs &&
              contract.costs.edges &&
              contract.costs.edges.map((edge: Maybe<CostEdge>) => {
                if (edge && edge.node !== null && edge.node !== undefined) {
                  return (
                    <>
                      <StyledMetaText variant={TextVariants.meta}>
                        {edge.node.title}
                      </StyledMetaText>
                      <StyledParagraphText variant={TextVariants.paragraph}>
                        <b>{formatEuro(edge.node.amount)}</b>
                      </StyledParagraphText>
                    </>
                  )
                }
              })}
            <StyledMetaText variant={TextVariants.meta}>
              * Preisberechnung des Mitgliedsbeitrages:
            </StyledMetaText>
            <StyledParagraphText variant={TextVariants.paragraph}>
              <b>
                {formatEuro(contract.weeklyFee)} x 52 Wochen / 12 Monate ={' '}
                {formatEuro(contract.monthlyFee)}
              </b>
            </StyledParagraphText>
          </StyledBox>
        </StyledCol>
        <StyledCol xs={12} sm={6} md={4} lg={4}>
          <StyledBox
            icon={<IconCheck />}
            background={BoxBackgrounds.TRANSPARENT}
          >
            <Headline importance={HeadlineImportances.h5}>
              Studio + Vertrag
            </Headline>
            <StyledMetaText variant={TextVariants.meta}>Studio</StyledMetaText>
            <StyledParagraphText variant={TextVariants.paragraph}>
              <b>{studio.title}</b>
            </StyledParagraphText>
            {contract.title && (
              <>
                <StyledMetaText variant={TextVariants.meta}>
                  Vertrag
                </StyledMetaText>
                <StyledParagraphText variant={TextVariants.paragraph}>
                  <b>{contract.title}</b>
                </StyledParagraphText>
              </>
            )}

            <StyledMetaText variant={TextVariants.meta}>Dauer</StyledMetaText>
            <StyledParagraphText variant={TextVariants.paragraph}>
              <b>
                {contract.duration} - {contract.durationDescription}
              </b>
            </StyledParagraphText>
          </StyledBox>
        </StyledCol>
        <StyledCol xs={12} sm={12} md={4} lg={4}>
          <StyledBox
            icon={<IconCheck />}
            background={BoxBackgrounds.TRANSPARENT}
          >
            <Headline importance={HeadlineImportances.h5}>Ihre Daten</Headline>
            <StyledMetaText variant={TextVariants.meta}>Name</StyledMetaText>
            <StyledParagraphText variant={TextVariants.paragraph}>
              <b>{formData.vorname + ' ' + formData.nachname}</b>
            </StyledParagraphText>
            <StyledMetaText variant={TextVariants.meta}>Adresse</StyledMetaText>
            <StyledParagraphText variant={TextVariants.paragraph}>
              <b>
                {formData.strasse + ' ' + formData.nr}
                <br />
                {formData.zip + ' ' + formData.ort}
                <br />
              </b>
            </StyledParagraphText>
            <StyledMetaText variant={TextVariants.meta}>Telefon</StyledMetaText>
            <StyledParagraphText variant={TextVariants.paragraph}>
              <b>{formData.telefon}</b>
            </StyledParagraphText>
            <StyledMetaText variant={TextVariants.meta}>E-Mail</StyledMetaText>
            <StyledParagraphText variant={TextVariants.paragraph}>
              <b>{formData.email}</b>
            </StyledParagraphText>
          </StyledBox>
        </StyledCol>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <Kleingedrucktes variant={TextVariants.meta}>
            {contract.infoInLayer}
          </Kleingedrucktes>
        </Col>
      </Row>
    </div>
  )
}

const getCostsObjectByType = (
  contract: Contract,
  type: string
): Cost | null => {
  if (!contract.costs || !contract.costs.edges) {
    return null
  }
  for (const edge of contract.costs.edges) {
    if (edge?.node?.costType === type) {
      return edge.node
    }
  }

  return null
}

const getCostsByType = (contract: Contract, type: string): number => {
  const costsObject = getCostsObjectByType(contract, type)

  return costsObject !== null ? costsObject.amount : 0
}

const getCostsInfoByType = (contract: Contract, type: string): string => {
  const costsObject = getCostsObjectByType(contract, type)

  return costsObject !== null ? costsObject.title : ''
}

const Payment: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const { studio, contract, handlePayment, formData, registrationError } = props
  const [loading, setLoading] = useState<boolean>(false)
  const theme: any = useTheme()

  const { register, handleSubmit, formState, watch } = useForm<FormPaymentType>(
    {
      resolver: yupResolver(schema) as Resolver<FormPaymentType>,
      defaultValues: {
        paymentmethod: PaymentMethod.PAYPAL,
        debitdate: DebitDate.FIRST,
        name: '',
        iban: '',
        directdebitmandate: false,
        sameperson: false,
      },
    }
  )
  const watchedFields = watch()
  const paypalContainerRef = useRef<HTMLDivElement | null>(null)
  const [paymentSession, setPaymentSession] =
    useState<CreateCheckoutSessionResponse | null>(null)
  const [lastContractStudioId, setLastContractStudioId] = useState<
    string | null
  >(null)
  const [paypalError, setPaypalError] = useState<string | null>(null)
  const lastSelectedPaymentMethod = useRef<string | null>(null)
  const { consentPaypal } = useUserCentrics()

  const { initPayment, loadPaypalButton } = useHandlePayment(
    studio,
    contract,
    formData.email
  )

  const loadPaypal = useCallback(async (): Promise<PaypalElement | null> => {
    if (
      paymentSession !== null ||
      !consentPaypal ||
      studio.adyenMerchantAccount === ''
    ) {
      return null
    }

    const curentPaymentSession = await initPayment()
    if (curentPaymentSession === null) {
      return null
    }

    return await loadPaypalButton(
      curentPaymentSession,
      paypalContainerRef.current as HTMLElement,
      () => {
        setPaypalError(null)
        setPaymentSession(curentPaymentSession)
      },
      () => {
        setPaypalError(
          'Es ist ein Fehler aufgetreten. Bitte versuche es erneut.'
        )
      }
    )
  }, [consentPaypal])

  useEffect(() => {
    if (
      watchedFields.paymentmethod === PaymentMethod.PAYPAL &&
      lastSelectedPaymentMethod.current === PaymentMethod.SEPA &&
      paymentSession === null
    ) {
      loadPaypal()
    }
    lastSelectedPaymentMethod.current = watchedFields.paymentmethod
  }, [watchedFields, consentPaypal])

  useEffect(() => {
    let component: PaypalElement | null = null

    loadPaypal()

    return () => {
      if (component) {
        component.unmount()
      }
    }
  }, [consentPaypal])

  useEffect(() => {
    const newContractStudioId = `${contract.id}#${studio.id}`
    if (lastContractStudioId !== newContractStudioId) {
      //reset payment if contract or studio changes
      setLastContractStudioId(newContractStudioId)
      setPaymentSession(null)
    }
  }, [contract, lastContractStudioId, studio])

  const onSubmit = (data: FormPaymentType) => {
    if (
      paymentSession === null &&
      data.paymentmethod === PaymentMethod.PAYPAL
    ) {
      setPaypalError('Bitte die Zahlung bei Paypal authorisieren.')
      return
    }
    setLoading(true)
    handlePayment(data, paymentSession)
  }

  const isPaypalAuthorized =
    watchedFields.paymentmethod === PaymentMethod.PAYPAL &&
    !!paymentSession &&
    consentPaypal &&
    studio.adyenMerchantAccount !== ''
  const isSepaAuthorized =
    watchedFields.paymentmethod === PaymentMethod.SEPA && formState.isValid
  const isBuyingDisabled = !(isPaypalAuthorized || isSepaAuthorized)

  const singleCosts = useMemo(
    () => getCostsByType(contract, 'SINGLE'),
    [contract]
  )
  const serviceCosts = useMemo(
    () => getCostsByType(contract, 'SERVICE'),
    [contract]
  )
  const singleCostsInfo = useMemo(
    () => getCostsInfoByType(contract, 'SINGLE'),
    [contract]
  )
  const serviceCostsInfo = useMemo(
    () => getCostsInfoByType(contract, 'SERVICE'),
    [contract]
  )

  return (
    <Root className={props.className} data-testid={'payment-root'}>
      <Form
        onError={(error) => console.log(error)}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Row>
          <StyledCol xs={12} sm={12} md={12} lg={12}>
            <PaymentBox>
              <Row>
                <Col xs={12} sm={12} md={12} lg={6}>
                  <Headline importance={HeadlineImportances.h5}>
                    Gewünschte Zahlungsart
                  </Headline>
                  <InputRadioGroup
                    label={''}
                    name="paymentmethod"
                    forId="paymentmethod"
                    error={formState.errors.paymentmethod ?? false}
                    errorText={formState.errors?.paymentmethod?.message}
                    radios={[
                      {
                        label: (
                          <>
                            <StyledLogoPaypalMini />
                            <span>PayPal</span>
                          </>
                        ),
                        forId: 'paymentmethod-paypal',
                        value: PaymentMethod.PAYPAL,
                        checked: true,
                      },
                      {
                        label: (
                          <>
                            <StyledLogoSepaMini />
                            <span>SEPA-Lastschrift (Bankeinzug)</span>
                          </>
                        ),
                        forId: 'paymentmethod-sepa',
                        value: PaymentMethod.SEPA,
                      },
                    ]}
                    register={register('paymentmethod')}
                  />
                </Col>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <Headline importance={HeadlineImportances.h5}>
                    Gewünschter Abbuchungstermin
                  </Headline>
                  <InputRadioGroup
                    label={''}
                    name="debitdate"
                    forId="debitdate"
                    error={formState.errors.debitdate ?? false}
                    errorText={formState.errors?.debitdate?.message}
                    radios={[
                      {
                        label: 'Zum 1. des Monats',
                        forId: 'debitdate-first',
                        value: DebitDate.FIRST,
                        checked: true,
                      },
                      {
                        label: 'Zum 15. des Monats',
                        forId: 'debitdate-fifthteenth',
                        value: DebitDate.FIFTHTEENTH,
                      },
                    ]}
                    register={register('debitdate')}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  {watchedFields.paymentmethod === PaymentMethod.PAYPAL && (
                    <PaypalContainer>
                      <PaypalInnerContainer>
                        {!paymentSession && (
                          <>
                            <div ref={paypalContainerRef} />
                          </>
                        )}
                        {!consentPaypal && (
                          <Text variant={TextVariants.paragraph}>
                            Paypal steht nicht zur Verfügung, da Sie nicht
                            zugestimmt haben.
                          </Text>
                        )}
                        {studio.adyenMerchantAccount === '' && (
                          <Text variant={TextVariants.paragraph}>
                            Paypal Zahlung ist für das Studio nicht möglich
                          </Text>
                        )}
                        {paymentSession && (
                          <>
                            <StyledLogoPaypal />
                            <Text variant={TextVariants.paragraph}>
                              Zahlung authorisiert
                            </Text>
                          </>
                        )}
                        <OrderedList>
                          <ListItem>
                            Bitte melde dich bei PayPal an und bestätige den
                            Clubvertrag.
                          </ListItem>
                          <ListItem>
                            Zuerst zahlst du die einmalige Aufnahmegebühr von{' '}
                            {formatEuro(singleCosts)}.
                          </ListItem>
                          <ListItem>
                            Danach wirst du wieder auf diese Seite geleitet.
                          </ListItem>
                          <ListItem>
                            <b>
                              Anschließend musst du deine Clubanmeldung noch
                              final abschließen.
                            </b>
                          </ListItem>
                        </OrderedList>
                      </PaypalInnerContainer>
                    </PaypalContainer>
                  )}
                  {watchedFields.paymentmethod === PaymentMethod.SEPA && (
                    <>
                      <StyledLogoSepa />
                      <InputText
                        register={register('name')}
                        forId="name"
                        label="Name"
                        required
                        error={formState.errors.name ?? false}
                        errorText={formState.errors?.name?.message}
                      />
                      <InputText
                        register={register('iban')}
                        forId="iban"
                        label="IBAN"
                        required
                        error={formState.errors?.iban ?? false}
                        errorText={formState.errors?.iban?.message}
                      />
                      <InputCheckbox
                        register={register('directdebitmandate')}
                        error={formState.errors?.directdebitmandate ?? false}
                        errorText={'Dies ist ein Pflichpfeld.'}
                        forId="directdebitmandate"
                        required
                        label={
                          'Ich ermächtige meinen Vertragspartner, Zahlungen von meinem Konto mittels Lastschrift einzuziehen. ' +
                          'Zugleich weise ich mein Kreditinstitut an, die von meinem Vertragspartner auf mein Konto gezogenen Lastschriften einzulösen. ' +
                          'Hinweis: Ich kann innerhalb von acht Wochen, beginnend mit dem Belastungsdatum, die Erstattung des belasteten Betrages verlangen. ' +
                          'Es gelten die mit meinem Kreditinstitut vereinbarten Bedingungen.'
                        }
                      />
                      <InputCheckbox
                        register={register('sameperson')}
                        error={formState.errors?.sameperson ?? false}
                        errorText={'Dies ist ein Pflichpfeld.'}
                        forId="sameperson"
                        required
                        label={
                          'Hiermit bestätige ich, dass es sich bei dem Kontoinhaber um die gleiche Person handelt und kein Drittzahler angegeben wird.'
                        }
                      />
                    </>
                  )}
                </Col>
              </Row>
              <Row>
                <StyledCol xs={12} sm={12} md={12} lg={12}>
                  <FinalizePaymentBox>
                    <Row>
                      <Col xs={12} sm={12} md={6} lg={6}>
                        <Headline importance={HeadlineImportances.h4}>
                          Bestellung abschließen
                        </Headline>

                        <FinalizeInfoRow>
                          <IconCheck
                            color={
                              theme.key === 'wellmaxx'
                                ? theme.palette.default.white
                                : theme.palette.default.black
                            }
                          />
                          <span>Studio: {studio.title}</span>
                        </FinalizeInfoRow>

                        <FinalizeInfoRow>
                          <IconCheck
                            color={
                              theme.key === 'wellmaxx'
                                ? theme.palette.default.white
                                : theme.palette.default.black
                            }
                          />
                          <span>{contract.duration}</span>
                        </FinalizeInfoRow>

                        {contract.specialOffer &&
                          contract.specialOffer.trim() !== '' && (
                            <FinalizeInfoRow>
                              <IconCheck
                                color={
                                  theme.key === 'wellmaxx'
                                    ? theme.palette.default.white
                                    : theme.palette.default.black
                                }
                              />
                              <span>{contract.specialOffer}</span>
                            </FinalizeInfoRow>
                          )}

                        <FinalizeCostsContainer>
                          <FinalizeCostsRow>
                            <StyledParagraphText variant={TextVariants.lead}>
                              <b>{formatEuro(contract.weeklyFee)}</b>
                            </StyledParagraphText>
                            <StyledMetaFinalizeCostsText
                              variant={TextVariants.meta}
                            >
                              wöchentlich
                            </StyledMetaFinalizeCostsText>

                            <FullPopup
                              trigger={
                                <div style={{ cursor: 'pointer' }}>
                                  <IconInfo
                                    color={
                                      theme.key === 'wellmaxx'
                                        ? theme.palette.default.white
                                        : theme.palette.default.black
                                    }
                                  />
                                </div>
                              }
                            >
                              {popupContent(contract, studio, formData)}
                            </FullPopup>
                          </FinalizeCostsRow>
                          <div>
                            {serviceCosts > 0 && (
                              <StyledMetaFinalizeCostsText
                                variant={TextVariants.meta}
                              >
                                zzgl. {formatEuro(singleCosts)} einmalige Kosten
                              </StyledMetaFinalizeCostsText>
                            )}
                          </div>
                        </FinalizeCostsContainer>

                        <StyledButton
                          disabled={false}
                          buttonType={ButtonTypes.SUBMIT}
                          modifier={ButtonModifier.TERTIARY}
                          loading={loading && !registrationError}
                        >
                          Kostenpflichtig bestellen
                        </StyledButton>
                        {watchedFields.paymentmethod === PaymentMethod.PAYPAL &&
                          paypalError && (
                            <ErrorText>
                              <b>{paypalError}</b>
                            </ErrorText>
                          )}
                        {registrationError && (
                          <ErrorText>
                            <b>{registrationError}</b>
                          </ErrorText>
                        )}
                      </Col>
                    </Row>
                  </FinalizePaymentBox>
                </StyledCol>
              </Row>
            </PaymentBox>
          </StyledCol>
        </Row>

        <Row></Row>
        <Row></Row>
      </Form>
    </Root>
  )
}

export { Payment }
