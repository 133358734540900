import React, { PropsWithChildren, useEffect, useRef, useState } from 'react'

import { Box, BoxBackgrounds } from '@components/atoms/Box/Box'
import Col from '@components/atoms/Grid/Col'
import Row from '@components/atoms/Grid/Row'
import Headline from '@components/atoms/Headline'
import { HeadlineImportances } from '@components/atoms/Headline/Headline'
import IconCheck from '@components/atoms/Icons/IconCheck'
import ListItem from '@components/atoms/ListItem'
import Text from '@components/atoms/Text'
import { TextVariants } from '@components/atoms/Text/Text'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import {
  Answer,
  AnswerRow,
  Answers,
  AnswerText,
  BigIconLargeSun,
  Question,
  Questionhead,
  Questionheadline,
  QuestionId,
  Questions,
  Result,
  ResultBox,
  Root,
  SameHeightCol,
  SameHeightRow,
  SkinBox,
  SkinBoxHeadline,
  SkinBoxListItemText,
  SkinBoxText,
  SkinBoxUnorderedList,
  SmallIconLargeSun,
  Test,
} from './SkinTypeTest.styles'

interface Props extends PropsWithClassName {
  dev?: boolean
}

interface SkinTypeTestAnswer {
  answer: string
  value: number
  icon?: React.ReactNode
}

interface SkinTypeTestQuestion {
  question: string
  answers: SkinTypeTestAnswer[]
}

export interface SkinType {
  id: number
  type: string
  colorName: string
  sunReaction: string
  commonFeatures: string[]
  colorHex: string
}

const allSkinTypes: SkinType[] = [
  {
    id: 1,
    type: 'Typ I',
    colorName: 'Sehr hell',
    sunReaction: 'Bräunung: keine',
    commonFeatures: [
      'Sommersprossen: sehr häufig',
      'Haarfarbe: rötlich bis hellblond',
      'Augenfarbe: blau, grau, grün',
    ],
    colorHex: '#FFFBF0', // sehr helles Beige
  },
  {
    id: 2,
    type: 'Typ II',
    colorName: 'Hell',
    sunReaction: 'Bräunung: kaum bis mäßig',
    commonFeatures: [
      'Sommersprossen: häufig',
      'Haarfarbe: blond bis hellbraun',
      'Augenfarbe: blau, grau, grün',
    ],
    colorHex: '#FFEBDC', // helles Beige
  },
  {
    id: 3,
    type: 'Typ III',
    colorName: 'Hell bis hellbraun',
    sunReaction: 'Bräunung: langsam',
    commonFeatures: [
      'Sommersprossen: selten',
      'Haarfarbe: dunkelblond bis braun',
      'Augenfarbe: blau, grau, grün, braun',
    ],
    colorHex: '#FDD9B5', // mittleres Beige
  },
  {
    id: 4,
    type: 'Typ IV',
    colorName: 'Hellbraun bis olivfarben',
    sunReaction: 'Bräunung: schnell',
    commonFeatures: [
      'Sommersprossen: keine',
      'Haarfarbe: dunkelbraun',
      'Augenfarbe: braun',
    ],
    colorHex: '#D2A172', // hellbraun
  },
]

const allQuestions: SkinTypeTestQuestion[] = [
  {
    question: 'Welchen Farbton weist Ihre unbestrahlte Haut auf?',
    answers: [
      { answer: 'Rötlich', value: 1 },
      { answer: 'Weißlich', value: 2 },
      { answer: 'Leicht beige', value: 3 },
      { answer: 'Bräunlich', value: 4 },
    ],
  },
  {
    question: 'Hat Ihre Haut Sommersprossen?',
    answers: [
      { answer: 'Ja, viele', value: 1 },
      { answer: 'Ja, einige', value: 2 },
      { answer: 'Ja, aber nur vereinzelt', value: 3 },
      { answer: 'Nein', value: 4 },
    ],
  },
  {
    question: 'Wie reagiert Ihre Gesichtshaut auf die Sonne?',
    answers: [
      { answer: 'Sehr empfindlich, meist Hautspannen', value: 1 },
      { answer: 'Empfindlich, teilweise Hautspannen', value: 2 },
      { answer: 'Normal empfindlich, nur selten Hautspannen', value: 3 },
      { answer: 'Unempfindlich, ohne Hautspannen', value: 4 },
    ],
  },
  {
    question:
      'Wie lange können Sie sich im Frühsommer in Deutschland am Mittag bei wolkenlosem Himmel in der Sonne aufhalten, ohne einen Sonnenbrand zu bekommen?',
    answers: [
      { answer: 'Weniger als 15 Minuten', value: 1 },
      { answer: 'Zwischen 15 und 25 Minuten', value: 2 },
      { answer: 'Zwischen 25 und 40 Minuten', value: 3 },
      { answer: 'Länger als 40 Minuten', value: 4 },
    ],
  },
  {
    question: 'Wie reagiert Ihre Haut auf ein längeres Sonnenbad?',
    answers: [
      { answer: 'Stets mit einem Sonnenbrand', value: 1 },
      { answer: 'Meist mit einem Sonnenbrand', value: 2 },
      { answer: 'Oftmals mit einem Sonnenbrand', value: 3 },
      { answer: 'Selten oder nie mit einem Sonnenbrand', value: 4 },
    ],
  },
  {
    question: 'Wie wirkt sich bei Ihnen ein Sonnenbrand aus?',
    answers: [
      {
        answer:
          'Kräftige Rötung, teilweise schmerzhaft und Bläschenbildung, danach Schälen der Haut',
        value: 1,
      },
      { answer: 'Deutliche Rötung, danach Schälen der Haut', value: 2 },
      { answer: 'Rötung, danach manchmal Schälen der Haut', value: 3 },
      { answer: 'Selten oder nie Rötung und Schälen der Haut', value: 4 },
    ],
  },
  {
    question:
      'Ist bei Ihnen nach einmaligem, längerem Sonnenbad anschließend ein Bräunungseffekt zu erkennen?',
    answers: [
      { answer: 'Nie', value: 1 },
      { answer: 'Meist nicht', value: 2 },
      { answer: 'Oftmals', value: 3 },
      { answer: 'Meist', value: 4 },
    ],
  },
  {
    question:
      'Wie entwickelt sich bei Ihnen die Hautbräunung nach wiederholtem Sonnenbad?',
    answers: [
      { answer: 'Kaum oder gar keine Bräunung', value: 1 },
      { answer: 'Leichte Bräunung nach mehreren Sonnenbädern', value: 2 },
      { answer: 'Fortschreitende, deutlicher werdende Bräunung', value: 3 },
      { answer: 'Schnell einsetzende und tiefe Bräunung', value: 4 },
    ],
  },
  {
    question:
      'Welche Angabe entspricht am ehesten Ihrer natürlichen Haarfarbe?',
    answers: [
      { answer: 'Rot bis rötlich blond', value: 1 },
      { answer: 'Hellblond bis blond', value: 2 },
      { answer: 'Dunkelblond bis braun', value: 3 },
      { answer: 'Dunkelbraun bis schwarz', value: 4 },
    ],
  },
  {
    question: 'Welche Farbe haben Ihre Augen?',
    answers: [
      { answer: 'Hellblau, hellgrau oder hellgrün', value: 1 },
      { answer: 'Blau, grau oder grün', value: 2 },
      { answer: 'Hellbraun oder dunkelgrau', value: 3 },
      { answer: 'Dunkelbraun', value: 4 },
    ],
  },
]

const SkinTypeTest: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const [done, setDone] = useState<boolean>(false)
  const [score, setScore] = useState<number>(0)
  const [index, setIndex] = useState<number>(0)
  const [result, setResult] = useState<number>(0)

  const questionCount: number = allQuestions.length

  const scrollRef = useRef<HTMLDivElement>(null)

  const { dev } = props

  const scrollToTarget = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  }

  const handleSetScore = (value: number): void => {
    const newScore = score + value
    const newResult = newScore / questionCount
    if (newResult < 0 || newResult > allSkinTypes.length) {
      return
    }
    setScore(newScore)
    setResult(newResult)
  }

  const handleSelectAnswer = (value: number, indexQuestion: number): void => {
    const newScore = score + value
    setScore(newScore)
    setResult(newScore / questionCount)
    if (indexQuestion + 1 === questionCount) {
      setDone(true)
    } else {
      setIndex(index + 1)
    }
    scrollToTarget()
  }

  let resultRounded = Math.round(result - 1)
  if (resultRounded < 0) {
    resultRounded = 0
  } else if (resultRounded >= allSkinTypes.length) {
    resultRounded = allSkinTypes.length - 1
  }

  return (
    <Root className={props.className} data-testid={'skin-type-test-root'}>
      {dev && (
        <div
          style={{
            display: 'inline-flex',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <p>
            score: {score}
            <br />
            index: {index}
            <br />
            result: {result}
            <br />
            resultRounded {resultRounded}
            <br />
            allSkinTypesCount {allSkinTypes.length}
            <br />
            resultRounded.type {allSkinTypes[resultRounded].type}
            <br />
            {allSkinTypes.length}
          </p>
          <div>
            <label htmlFor="done">
              <input
                id="done"
                placeholder={'Set done'}
                type="checkbox"
                checked={done}
                onChange={(event) => setDone(event.target.checked)}
              />
              done
            </label>
          </div>
          <div>
            <button onClick={() => handleSetScore(-5)}>Minus</button>
            <button onClick={() => handleSetScore(5)}>Plus</button>
          </div>
        </div>
      )}
      <Test ref={scrollRef}>
        {!done && (
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <Headline importance={HeadlineImportances.h2}>
                Dein Test:
              </Headline>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12}>
              <Questions>
                {allQuestions.map((question, indexQuestion) => {
                  return (
                    <Question
                      key={question.question + indexQuestion}
                      background={BoxBackgrounds.GREYLIGHT}
                      visible={indexQuestion === index}
                    >
                      <Questionhead>
                        <Questionheadline importance={HeadlineImportances.h5}>
                          {question.question}
                        </Questionheadline>
                        <QuestionId variant={TextVariants.paragraph}>
                          {indexQuestion + 1}/{questionCount}
                        </QuestionId>
                      </Questionhead>
                      <Answers>
                        <AnswerRow>
                          {question.answers.map((answer, indexAnswer) => {
                            return (
                              <Col
                                key={
                                  answer.answer + indexQuestion + indexAnswer
                                }
                                xs={12}
                                sm={6}
                                md={6}
                                lg={4}
                              >
                                <Answer
                                  delay={indexQuestion}
                                  onClick={() =>
                                    handleSelectAnswer(
                                      answer.value,
                                      indexQuestion
                                    )
                                  }
                                  icon={
                                    answer.icon ?? (
                                      <AnswerText variant={TextVariants.lead}>
                                        <b>{indexAnswer + 1}</b>
                                      </AnswerText>
                                    )
                                  }
                                  background={BoxBackgrounds.WHITE}
                                  key={
                                    answer.answer + indexQuestion + indexAnswer
                                  }
                                >
                                  <AnswerText variant={TextVariants.paragraph}>
                                    {answer.answer}
                                  </AnswerText>
                                </Answer>
                              </Col>
                            )
                          })}
                        </AnswerRow>
                      </Answers>
                    </Question>
                  )
                })}
              </Questions>
            </Col>
          </Row>
        )}
        {done && (
          <>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <Headline importance={HeadlineImportances.h2}>
                  Dein Ergebnis:
                </Headline>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12}>
                <ResultBox
                  background={
                    resultRounded < allSkinTypes.length
                      ? allSkinTypes[resultRounded].colorHex
                      : allSkinTypes[allSkinTypes.length - 1].colorHex
                  }
                >
                  <Result>
                    <SkinBoxHeadline importance={HeadlineImportances.h1}>
                      {allSkinTypes[resultRounded].type}
                    </SkinBoxHeadline>
                    <SkinBoxHeadline importance={HeadlineImportances.h2}>
                      {allSkinTypes[resultRounded].colorName}
                    </SkinBoxHeadline>
                    <SkinBoxText variant={TextVariants.lead}>
                      {allSkinTypes[resultRounded].sunReaction}
                    </SkinBoxText>
                    <SkinBoxUnorderedList
                      background={allSkinTypes[resultRounded].colorHex}
                    >
                      {allSkinTypes[resultRounded].commonFeatures.map(
                        (commonFeature, index) => {
                          return (
                            <ListItem
                              icon={<IconCheck />}
                              key={index + commonFeature}
                            >
                              <SkinBoxListItemText variant={TextVariants.lead}>
                                {commonFeature}
                              </SkinBoxListItemText>
                            </ListItem>
                          )
                        }
                      )}
                    </SkinBoxUnorderedList>
                    <SkinBoxText variant={TextVariants.lead}>
                      <b>{`${result.toFixed(2)} Punkte`}</b>
                    </SkinBoxText>
                  </Result>
                  <SmallIconLargeSun
                    fill={allSkinTypes[resultRounded].colorHex}
                  />
                  <BigIconLargeSun
                    fill={allSkinTypes[resultRounded].colorHex}
                  />
                </ResultBox>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <Headline importance={HeadlineImportances.h5}>
                  Fitzpatrick-Hauttypskala
                </Headline>
                <Text variant={TextVariants.paragraph}>
                  Die Hauttypen des Menschen werden häufig anhand der
                  Fitzpatrick-Hauttypskala klassifiziert, die 1975 von Thomas
                  Fitzpatrick, MD, von der Harvard Medical School entwickelt
                  wurde. Die Fitzpatrick-Skala kategorisiert den Hauttyp
                  basierend darauf, wie er auf ultraviolettes (UV) Licht
                  reagiert, insbesondere seine Tendenz zu bräunen.
                </Text>
              </Col>
            </Row>
            <SameHeightRow>
              {allSkinTypes.map((skintype) => {
                return (
                  <SameHeightCol
                    key={skintype.id + skintype.colorName}
                    xs={12}
                    sm={6}
                    md={4}
                    lg={4}
                  >
                    <SkinBox
                      key={skintype.colorName + skintype.type}
                      background={skintype.colorHex}
                    >
                      <SkinBoxHeadline importance={HeadlineImportances.h3}>
                        {skintype.type}
                      </SkinBoxHeadline>
                      <SkinBoxText variant={TextVariants.lead}>
                        {skintype.colorName}
                      </SkinBoxText>
                      <SkinBoxText variant={TextVariants.paragraph}>
                        {skintype.sunReaction}
                      </SkinBoxText>
                      <SkinBoxUnorderedList background={skintype.colorHex}>
                        {skintype.commonFeatures.map((commonFeature, index) => {
                          return (
                            <ListItem
                              icon={<IconCheck />}
                              key={index + commonFeature}
                            >
                              <SkinBoxListItemText
                                variant={TextVariants.paragraph}
                              >
                                {commonFeature}
                              </SkinBoxListItemText>
                            </ListItem>
                          )
                        })}
                      </SkinBoxUnorderedList>
                      <SkinBoxText variant={TextVariants.meta}>
                        {skintype.id} Punkt{skintype.id !== 1 ? 'e' : ''}
                      </SkinBoxText>
                    </SkinBox>
                  </SameHeightCol>
                )
              })}
            </SameHeightRow>
          </>
        )}
      </Test>
    </Root>
  )
}

export { SkinTypeTest }
